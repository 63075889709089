import React from "react"
import Layout from "@components/kaigai-fx/layout";
import 'tw-elements';

// 関数呼び出し
import { TermComponent } from "@components/kaigai-fx/term-component.js";

const TermPageT = () => {
  // 設定項目 ================================================================================================
  const myLine = 'た行'
  const myID = 'ta_'
  const pathName = 'term-ta';
  const termItem = [ // 改行するときは<br>を追加
  {
    Title:'高値',
    Content:'特定の期間のなかで最も高い価格のことです。'
  },
  {
    Title:'建玉',
    Content:'取引約定後に反対売買されないまま残っている未決済分の約定のことです。<br>「玉（ぎょく）」「ポジション」とも言います。'
  },
  {
    Title:'注文中証拠金',
    Content:'注文が約定したら使用することになる証拠金額のことです。'
  },
  {
    Title:'通貨ペア',
    Content:'取引で売買する2ヵ国の通貨の組み合わせのことです。<br>「/」で区切って並べて表記します。<br>例：「米ドル/円を買う」：米ドルを買って円を売る'
  },
  {
    Title:'店頭外国為替証拠金取引',
    Content:'資金を証拠金として預け、証拠金の範囲内で日本円と米ドルなど、<br>２つの国の通貨の為替相場を予測して売買を行う金融商品です。'
  },
  {
    Title:'店頭金融先物取引',
    Content:'外国為替証拠金取引（FX取引）のように、金融商品取引所が開設している「外国金融商品市場」などを経由せずに<br>通貨、金利、株式などの原資産と呼ばれる金融商品のデリバティブ取引を行うこと'
  },
  {
    Title:'特定投資家',
    Content:'金融商品に対して豊富な知識やリスク管理能力、経験や財産を持ち合わせている、<br>機関投資家を始めとしたプロの投資家のこと。<br>特殊法人や独立行政法人、資本金額が５億円以上の株式会社を始め<br>投資者保護基金や預金保険機構、保険契約者保護機構などが含まれます。'
  },
  {
    Title:'途転注文',
    Content:'途転注文はリバース注文とも言われ、同一銘柄で保有している売買区分を一度に転換する注文方法。 <b></b>買い又は売りポジションを決済すると同時に、反対の売買ポジションを持つ場合に利用します。'
  },
  {
    Title:'必要証拠金',
    Content:'取引をするために必要な資金をFX業者に預け入れるお金(担保)のこと。'
  },
  {
    Title:'トレール注文',
    Content:'トレールは「追いかける」という意味で、レートが上昇すると同時に逆指値注文のレートも一定の値幅でついていく注文方法'
  }
  ]
  // =================================================================================================================================
  // term-component.jsにて詳細設定
  let termPageContent = TermComponent( myLine, myID, termItem, pathName ); 
  
  return (
  <>
    <Layout>
      {termPageContent}
    </Layout>
  </>
  )
}
export default TermPageT